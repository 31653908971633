.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }
  .Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }
  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--top-right {
    top: 1em;
    right: 1em; }
  .Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }
  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
      bottom: 0; }
    .Toastify__toast-container--rtl {
      right: 0;
      left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
  .Toastify__toast--rtl {
    direction: rtl; }
  .Toastify__toast--default {
    background: #fff;
    color: #aaa; }
  .Toastify__toast--info {
    background: #3498db; }
  .Toastify__toast--success {
    background: #07bc0c; }
  .Toastify__toast--warning {
    background: #f1c40f; }
  .Toastify__toast--error {
    background: #e74c3c; }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }
  .Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left; }
  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards; }
  .Toastify__progress-bar--controlled {
    transition: transform .2s; }
  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right; }
  .Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown; }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut; }

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  animation-name: Toastify__flipOut; }

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
:root {
  --palette-red-50: rgb(255, 235, 238);
  --palette-red-100: rgb(255, 205, 210);
  --palette-red-200: rgb(239, 154, 154);
  --palette-red-300: rgb(229, 115, 115);
  --palette-red-400: rgb(239, 83, 80);
  --palette-red-500: rgb(244, 67, 54);
  --palette-red-600: rgb(229, 57, 53);
  --palette-red-700: rgb(211, 47, 47);
  --palette-red-800: rgb(198, 40, 40);
  --palette-red-900: rgb(183, 28, 28);
  --palette-red-a100: rgb(255, 138, 128);
  --palette-red-a200: rgb(255, 82, 82);
  --palette-red-a400: rgb(255, 23, 68);
  --palette-red-a700: rgb(213, 0, 0);
  --palette-pink-50: rgb(252, 228, 236);
  --palette-pink-100: rgb(248, 187, 208);
  --palette-pink-200: rgb(244, 143, 177);
  --palette-pink-300: rgb(240, 98, 146);
  --palette-pink-400: rgb(236, 64, 122);
  --palette-pink-500: rgb(233, 30, 99);
  --palette-pink-600: rgb(216, 27, 96);
  --palette-pink-700: rgb(194, 24, 91);
  --palette-pink-800: rgb(173, 20, 87);
  --palette-pink-900: rgb(136, 14, 79);
  --palette-pink-a100: rgb(255, 128, 171);
  --palette-pink-a200: rgb(255, 64, 129);
  --palette-pink-a400: rgb(245, 0, 87);
  --palette-pink-a700: rgb(197, 17, 98);
  --palette-purple-50: rgb(243, 229, 245);
  --palette-purple-100: rgb(225, 190, 231);
  --palette-purple-200: rgb(206, 147, 216);
  --palette-purple-300: rgb(186, 104, 200);
  --palette-purple-400: rgb(171, 71, 188);
  --palette-purple-500: rgb(156, 39, 176);
  --palette-purple-600: rgb(142, 36, 170);
  --palette-purple-700: rgb(123, 31, 162);
  --palette-purple-800: rgb(106, 27, 154);
  --palette-purple-900: rgb(74, 20, 140);
  --palette-purple-a100: rgb(234, 128, 252);
  --palette-purple-a200: rgb(224, 64, 251);
  --palette-purple-a400: rgb(213, 0, 249);
  --palette-purple-a700: rgb(170, 0, 255);
  --palette-deep-purple-50: rgb(237, 231, 246);
  --palette-deep-purple-100: rgb(209, 196, 233);
  --palette-deep-purple-200: rgb(179, 157, 219);
  --palette-deep-purple-300: rgb(149, 117, 205);
  --palette-deep-purple-400: rgb(126, 87, 194);
  --palette-deep-purple-500: rgb(103, 58, 183);
  --palette-deep-purple-600: rgb(94, 53, 177);
  --palette-deep-purple-700: rgb(81, 45, 168);
  --palette-deep-purple-800: rgb(69, 39, 160);
  --palette-deep-purple-900: rgb(49, 27, 146);
  --palette-deep-purple-a100: rgb(179, 136, 255);
  --palette-deep-purple-a200: rgb(124, 77, 255);
  --palette-deep-purple-a400: rgb(101, 31, 255);
  --palette-deep-purple-a700: rgb(98, 0, 234);
  --palette-indigo-50: rgb(232, 234, 246);
  --palette-indigo-100: rgb(197, 202, 233);
  --palette-indigo-200: rgb(159, 168, 218);
  --palette-indigo-300: rgb(121, 134, 203);
  --palette-indigo-400: rgb(92, 107, 192);
  --palette-indigo-500: rgb(63, 81, 181);
  --palette-indigo-600: rgb(57, 73, 171);
  --palette-indigo-700: rgb(48, 63, 159);
  --palette-indigo-800: rgb(40, 53, 147);
  --palette-indigo-900: rgb(26, 35, 126);
  --palette-indigo-a100: rgb(140, 158, 255);
  --palette-indigo-a200: rgb(83, 109, 254);
  --palette-indigo-a400: rgb(61, 90, 254);
  --palette-indigo-a700: rgb(48, 79, 254);
  --palette-blue-50: rgb(227, 242, 253);
  --palette-blue-100: rgb(187, 222, 251);
  --palette-blue-200: rgb(144, 202, 249);
  --palette-blue-300: rgb(100, 181, 246);
  --palette-blue-400: rgb(66, 165, 245);
  --palette-blue-500: rgb(33, 150, 243);
  --palette-blue-600: rgb(30, 136, 229);
  --palette-blue-700: rgb(25, 118, 210);
  --palette-blue-800: rgb(21, 101, 192);
  --palette-blue-900: rgb(13, 71, 161);
  --palette-blue-a100: rgb(130, 177, 255);
  --palette-blue-a200: rgb(68, 138, 255);
  --palette-blue-a400: rgb(41, 121, 255);
  --palette-blue-a700: rgb(41, 98, 255);
  --palette-light-blue-50: rgb(225, 245, 254);
  --palette-light-blue-100: rgb(179, 229, 252);
  --palette-light-blue-200: rgb(129, 212, 250);
  --palette-light-blue-300: rgb(79, 195, 247);
  --palette-light-blue-400: rgb(41, 182, 246);
  --palette-light-blue-500: rgb(3, 169, 244);
  --palette-light-blue-600: rgb(3, 155, 229);
  --palette-light-blue-700: rgb(2, 136, 209);
  --palette-light-blue-800: rgb(2, 119, 189);
  --palette-light-blue-900: rgb(1, 87, 155);
  --palette-light-blue-a100: rgb(128, 216, 255);
  --palette-light-blue-a200: rgb(64, 196, 255);
  --palette-light-blue-a400: rgb(0, 176, 255);
  --palette-light-blue-a700: rgb(0, 145, 234);
  --palette-cyan-50: rgb(224, 247, 250);
  --palette-cyan-100: rgb(178, 235, 242);
  --palette-cyan-200: rgb(128, 222, 234);
  --palette-cyan-300: rgb(77, 208, 225);
  --palette-cyan-400: rgb(38, 198, 218);
  --palette-cyan-500: rgb(0, 188, 212);
  --palette-cyan-600: rgb(0, 172, 193);
  --palette-cyan-700: rgb(0, 151, 167);
  --palette-cyan-800: rgb(0, 131, 143);
  --palette-cyan-900: rgb(0, 96, 100);
  --palette-cyan-a100: rgb(132, 255, 255);
  --palette-cyan-a200: rgb(24, 255, 255);
  --palette-cyan-a400: rgb(0, 229, 255);
  --palette-cyan-a700: rgb(0, 184, 212);
  --palette-teal-50: rgb(224, 242, 241);
  --palette-teal-100: rgb(178, 223, 219);
  --palette-teal-200: rgb(128, 203, 196);
  --palette-teal-300: rgb(77, 182, 172);
  --palette-teal-400: rgb(38, 166, 154);
  --palette-teal-500: rgb(0, 150, 136);
  --palette-teal-600: rgb(0, 137, 123);
  --palette-teal-700: rgb(0, 121, 107);
  --palette-teal-800: rgb(0, 105, 92);
  --palette-teal-900: rgb(0, 77, 64);
  --palette-teal-a100: rgb(167, 255, 235);
  --palette-teal-a200: rgb(100, 255, 218);
  --palette-teal-a400: rgb(29, 233, 182);
  --palette-teal-a700: rgb(0, 191, 165);
  --palette-green-50: rgb(232, 245, 233);
  --palette-green-100: rgb(200, 230, 201);
  --palette-green-200: rgb(165, 214, 167);
  --palette-green-300: rgb(129, 199, 132);
  --palette-green-400: rgb(102, 187, 106);
  --palette-green-500: rgb(76, 175, 80);
  --palette-green-600: rgb(67, 160, 71);
  --palette-green-700: rgb(56, 142, 60);
  --palette-green-800: rgb(46, 125, 50);
  --palette-green-900: rgb(27, 94, 32);
  --palette-green-a100: rgb(185, 246, 202);
  --palette-green-a200: rgb(105, 240, 174);
  --palette-green-a400: rgb(0, 230, 118);
  --palette-green-a700: rgb(0, 200, 83);
  --palette-light-green-50: rgb(241, 248, 233);
  --palette-light-green-100: rgb(220, 237, 200);
  --palette-light-green-200: rgb(197, 225, 165);
  --palette-light-green-300: rgb(174, 213, 129);
  --palette-light-green-400: rgb(156, 204, 101);
  --palette-light-green-500: rgb(139, 195, 74);
  --palette-light-green-600: rgb(124, 179, 66);
  --palette-light-green-700: rgb(104, 159, 56);
  --palette-light-green-800: rgb(85, 139, 47);
  --palette-light-green-900: rgb(51, 105, 30);
  --palette-light-green-a100: rgb(204, 255, 144);
  --palette-light-green-a200: rgb(178, 255, 89);
  --palette-light-green-a400: rgb(118, 255, 3);
  --palette-light-green-a700: rgb(100, 221, 23);
  --palette-lime-50: rgb(249, 251, 231);
  --palette-lime-100: rgb(240, 244, 195);
  --palette-lime-200: rgb(230, 238, 156);
  --palette-lime-300: rgb(220, 231, 117);
  --palette-lime-400: rgb(212, 225, 87);
  --palette-lime-500: rgb(205, 220, 57);
  --palette-lime-600: rgb(192, 202, 51);
  --palette-lime-700: rgb(175, 180, 43);
  --palette-lime-800: rgb(158, 157, 36);
  --palette-lime-900: rgb(130, 119, 23);
  --palette-lime-a100: rgb(244, 255, 129);
  --palette-lime-a200: rgb(238, 255, 65);
  --palette-lime-a400: rgb(198, 255, 0);
  --palette-lime-a700: rgb(174, 234, 0);
  --palette-yellow-50: rgb(255, 253, 231);
  --palette-yellow-100: rgb(255, 249, 196);
  --palette-yellow-200: rgb(255, 245, 157);
  --palette-yellow-300: rgb(255, 241, 118);
  --palette-yellow-400: rgb(255, 238, 88);
  --palette-yellow-500: rgb(255, 235, 59);
  --palette-yellow-600: rgb(253, 216, 53);
  --palette-yellow-700: rgb(251, 192, 45);
  --palette-yellow-800: rgb(249, 168, 37);
  --palette-yellow-900: rgb(245, 127, 23);
  --palette-yellow-a100: rgb(255, 255, 141);
  --palette-yellow-a200: rgb(255, 255, 0);
  --palette-yellow-a400: rgb(255, 234, 0);
  --palette-yellow-a700: rgb(255, 214, 0);
  --palette-amber-50: rgb(255, 248, 225);
  --palette-amber-100: rgb(255, 236, 179);
  --palette-amber-200: rgb(255, 224, 130);
  --palette-amber-300: rgb(255, 213, 79);
  --palette-amber-400: rgb(255, 202, 40);
  --palette-amber-500: rgb(255, 193, 7);
  --palette-amber-600: rgb(255, 179, 0);
  --palette-amber-700: rgb(255, 160, 0);
  --palette-amber-800: rgb(255, 143, 0);
  --palette-amber-900: rgb(255, 111, 0);
  --palette-amber-a100: rgb(255, 229, 127);
  --palette-amber-a200: rgb(255, 215, 64);
  --palette-amber-a400: rgb(255, 196, 0);
  --palette-amber-a700: rgb(255, 171, 0);
  --palette-orange-50: rgb(255, 243, 224);
  --palette-orange-100: rgb(255, 224, 178);
  --palette-orange-200: rgb(255, 204, 128);
  --palette-orange-300: rgb(255, 183, 77);
  --palette-orange-400: rgb(255, 167, 38);
  --palette-orange-500: rgb(255, 152, 0);
  --palette-orange-600: rgb(251, 140, 0);
  --palette-orange-700: rgb(245, 124, 0);
  --palette-orange-800: rgb(239, 108, 0);
  --palette-orange-900: rgb(230, 81, 0);
  --palette-orange-a100: rgb(255, 209, 128);
  --palette-orange-a200: rgb(255, 171, 64);
  --palette-orange-a400: rgb(255, 145, 0);
  --palette-orange-a700: rgb(255, 109, 0);
  --palette-deep-orange-50: rgb(251, 233, 231);
  --palette-deep-orange-100: rgb(255, 204, 188);
  --palette-deep-orange-200: rgb(255, 171, 145);
  --palette-deep-orange-300: rgb(255, 138, 101);
  --palette-deep-orange-400: rgb(255, 112, 67);
  --palette-deep-orange-500: rgb(255, 87, 34);
  --palette-deep-orange-600: rgb(244, 81, 30);
  --palette-deep-orange-700: rgb(230, 74, 25);
  --palette-deep-orange-800: rgb(216, 67, 21);
  --palette-deep-orange-900: rgb(191, 54, 12);
  --palette-deep-orange-a100: rgb(255, 158, 128);
  --palette-deep-orange-a200: rgb(255, 110, 64);
  --palette-deep-orange-a400: rgb(255, 61, 0);
  --palette-deep-orange-a700: rgb(221, 44, 0);
  --palette-brown-50: rgb(239, 235, 233);
  --palette-brown-100: rgb(215, 204, 200);
  --palette-brown-200: rgb(188, 170, 164);
  --palette-brown-300: rgb(161, 136, 127);
  --palette-brown-400: rgb(141, 110, 99);
  --palette-brown-500: rgb(121, 85, 72);
  --palette-brown-600: rgb(109, 76, 65);
  --palette-brown-700: rgb(93, 64, 55);
  --palette-brown-800: rgb(78, 52, 46);
  --palette-brown-900: rgb(62, 39, 35);
  --palette-grey-50: rgb(250, 250, 250);
  --palette-grey-100: rgb(245, 245, 245);
  --palette-grey-200: rgb(238, 238, 238);
  --palette-grey-300: rgb(224, 224, 224);
  --palette-grey-400: rgb(189, 189, 189);
  --palette-grey-500: rgb(158, 158, 158);
  --palette-grey-600: rgb(117, 117, 117);
  --palette-grey-700: rgb(97, 97, 97);
  --palette-grey-800: rgb(66, 66, 66);
  --palette-grey-900: rgb(33, 33, 33);
  --palette-blue-grey-50: rgb(236, 239, 241);
  --palette-blue-grey-100: rgb(207, 216, 220);
  --palette-blue-grey-200: rgb(176, 190, 197);
  --palette-blue-grey-300: rgb(144, 164, 174);
  --palette-blue-grey-400: rgb(120, 144, 156);
  --palette-blue-grey-500: rgb(96, 125, 139);
  --palette-blue-grey-600: rgb(84, 110, 122);
  --palette-blue-grey-700: rgb(69, 90, 100);
  --palette-blue-grey-800: rgb(55, 71, 79);
  --palette-blue-grey-900: rgb(38, 50, 56);
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-dark-contrast: var(--color-white);
  --color-light-contrast: var(--color-black);
}
:root {
  --color-divider: var(--palette-grey-200);
  --color-background: var(--color-white);
  --color-text: var(--palette-grey-900);
  --color-text-secondary: var(--palette-grey-600);
  --color-primary: var(--palette-indigo-500);
  --color-primary-dark: var(--palette-indigo-700);
  --color-accent: var(--palette-pink-a200);
  --color-accent-dark: var(--palette-pink-700);
  --color-primary-contrast: var(--color-dark-contrast);
  --color-accent-contrast: var(--color-dark-contrast);
  --unit: 10px;
  --preferred-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;
  --shadow-2p:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --shadow-3p:
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --shadow-4p:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-6p:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --shadow-8p:
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --shadow-16p:
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --shadow-key-umbra-opacity: 0.2;
  --shadow-key-penumbra-opacity: 0.14;
  --shadow-ambient-shadow-opacity: 0.12;
  --zdepth-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  --zdepth-shadow-2: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  --zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);
  --z-index-highest: 300;
  --z-index-higher: 200;
  --z-index-high: 100;
  --z-index-normal: 1;
  --z-index-low: -100;
  --z-index-lower: -200
}
:root {
  --input-padding: calc(2 * var(--unit));
  --input-field-padding: calc(0.8 * var(--unit));
  --input-field-font-size: calc(1.6 * var(--unit));
  --input-field-height: calc(var(--input-field-padding) * 2 + var(--input-field-font-size) * 1.4);
  --input-label-font-size: calc(1.2 * var(--unit));
  --input-focus-label-top: calc(0.6 * var(--unit));
  --input-text-background-color: transparent;
  --input-text-label-color: color(var(--color-black) a(26%));
  --input-text-bottom-border-color: color(var(--color-black) a(12%));
  --input-text-highlight-color: var(--color-primary);
  --input-text-disabled-color: var(--input-text-bottom-border-color);
  --input-text-disabled-text-color: var(--input-text-label-color);
  --input-text-error-color: rgb(222, 50, 38);
  --input-text-required-color: rgb(222, 50, 38);
  --input-underline-height: calc(2 * var(--unit));
  --input-icon-font-size: calc(2.4 * var(--unit));
  --input-icon-size: calc(2 * var(--input-icon-font-size));
  --input-icon-offset: calc(1.6 * var(--unit));
  --input-icon-right-space: calc(2 * var(--unit));
  --input-chevron-offset: calc(0.8 * var(--unit));
  --input-hint-opacity: 1;
}
.input {
  padding: var(--input-padding) 0;
  position: relative;
  box-sizing: border-box;
  font-family: var(--preferred-font);
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%
}
.input *,
    .input *::after,
    .input *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  -webkit-touch-callout: none;
}
.input.withIcon {
  margin-left: calc(var(--input-icon-size) + var(--input-icon-right-space));
}
.icon {
  color: var(--input-text-label-color);
  display: block;
  font-size: var(--input-icon-font-size) !important;
  height: var(--input-icon-size);
  left: calc(-1 * (var(--input-icon-size) + var(--input-icon-right-space)));
  line-height: var(--input-icon-size) !important;
  position: absolute;
  text-align: center;
  top: var(--input-icon-offset);
  transition: color var(--animation-duration) var(--animation-curve-default);
  width: var(--input-icon-size);
}
.inputElement {
  background-color: var(--input-text-background-color);
  border-bottom: 1px solid var(--input-text-bottom-border-color);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: var(--color-text);
  display: block;
  font-size: var(--input-field-font-size);
  outline: none;
  padding: var(--input-field-padding) 0;
  width: 100%
}
.inputElement:focus:not([disabled]):not([readonly]) ~ .bar::before,
    .inputElement:focus:not([disabled]):not([readonly]) ~ .bar::after {
  width: 50%;
}
.inputElement:focus:not([disabled]):not([readonly]) ~ .label:not(.fixed) {
  color: var(--input-text-highlight-color);
}
.inputElement:focus:not([disabled]):not([readonly]) ~ .label > .required {
  color: var(--input-text-required-color);
}
.inputElement:focus:not([disabled]):not([readonly]) ~ .hint {
  display: block;
  opacity: var(--input-hint-opacity);
}
.inputElement:focus:not([disabled]):not([readonly]) ~ .icon {
  color: var(--input-text-highlight-color);
}
.inputElement:focus:not([disabled]):not([readonly]).filled ~ .hint {
  opacity: 0;
}
.inputElement:focus:not([disabled]):not([readonly]) ~ .label:not(.fixed), .inputElement.filled ~ .label:not(.fixed), .inputElement[type='date'] ~ .label:not(.fixed), .inputElement[type='time'] ~ .label:not(.fixed) {
  font-size: var(--input-label-font-size);
  top: var(--input-focus-label-top);
}
.inputElement.filled ~ .label.fixed,
  .inputElement.filled ~ .hint {
  display: none;
}
.label {
  color: var(--input-text-label-color);
  font-size: var(--input-field-font-size);
  left: 0;
  line-height: var(--input-field-font-size);
  pointer-events: none;
  position: absolute;
  top: calc(var(--input-padding) + 1.5 * var(--input-field-padding));
  transition-duration: var(--animation-duration);
  transition-property: top, font-size, color;
  transition-timing-function: var(--animation-curve-default)
}
.label.fixed ~ .hint {
  display: none;
}
.hint {
  color: var(--input-text-label-color);
  font-size: var(--input-field-font-size);
  left: 0;
  line-height: var(--input-field-font-size);
  opacity: var(--input-hint-opacity);
  pointer-events: none;
  position: absolute;
  top: calc(var(--input-padding) + 1.5 * var(--input-field-padding));
  transition-duration: var(--animation-duration);
  transition-property: opacity;
  transition-timing-function: var(--animation-curve-default);
}
.bar {
  display: block;
  position: relative;
  width: 100%
}
.bar::before,
  .bar::after {
  background-color: var(--input-text-highlight-color);
  bottom: 0;
  content: '';
  height: 2px;
  position: absolute;
  transition-duration: 0.2s;
  transition-property: width, background-color;
  transition-timing-function: var(--animation-curve-default);
  width: 0;
}
.bar::before {
  left: 50%;
}
.bar::after {
  right: 50%;
}
.error,
.counter {
  color: var(--input-text-error-color);
  font-size: var(--input-label-font-size);
  line-height: var(--input-underline-height);
  margin-bottom: calc(-1 * var(--input-underline-height));
}
.counter {
  color: var(--input-text-label-color);
  position: absolute;
  right: 0;
}
.disabled > .inputElement {
  border-bottom-style: dotted;
  color: var(--input-text-disabled-text-color);
}
.errored {
  padding-bottom: 0
}
.errored > .inputElement {
  border-bottom-color: var(--input-text-error-color);
  margin-top: 1px;
}
.errored > .counter,
  .errored > .label {
  color: var(--input-text-error-color);
}
.errored > .label > .required {
  color: var(--input-text-required-color);
}
.hidden {
  display: none;
}

:root {
  --palette-red-50: rgb(255, 235, 238);
  --palette-red-100: rgb(255, 205, 210);
  --palette-red-200: rgb(239, 154, 154);
  --palette-red-300: rgb(229, 115, 115);
  --palette-red-400: rgb(239, 83, 80);
  --palette-red-500: rgb(244, 67, 54);
  --palette-red-600: rgb(229, 57, 53);
  --palette-red-700: rgb(211, 47, 47);
  --palette-red-800: rgb(198, 40, 40);
  --palette-red-900: rgb(183, 28, 28);
  --palette-red-a100: rgb(255, 138, 128);
  --palette-red-a200: rgb(255, 82, 82);
  --palette-red-a400: rgb(255, 23, 68);
  --palette-red-a700: rgb(213, 0, 0);
  --palette-pink-50: rgb(252, 228, 236);
  --palette-pink-100: rgb(248, 187, 208);
  --palette-pink-200: rgb(244, 143, 177);
  --palette-pink-300: rgb(240, 98, 146);
  --palette-pink-400: rgb(236, 64, 122);
  --palette-pink-500: rgb(233, 30, 99);
  --palette-pink-600: rgb(216, 27, 96);
  --palette-pink-700: rgb(194, 24, 91);
  --palette-pink-800: rgb(173, 20, 87);
  --palette-pink-900: rgb(136, 14, 79);
  --palette-pink-a100: rgb(255, 128, 171);
  --palette-pink-a200: rgb(255, 64, 129);
  --palette-pink-a400: rgb(245, 0, 87);
  --palette-pink-a700: rgb(197, 17, 98);
  --palette-purple-50: rgb(243, 229, 245);
  --palette-purple-100: rgb(225, 190, 231);
  --palette-purple-200: rgb(206, 147, 216);
  --palette-purple-300: rgb(186, 104, 200);
  --palette-purple-400: rgb(171, 71, 188);
  --palette-purple-500: rgb(156, 39, 176);
  --palette-purple-600: rgb(142, 36, 170);
  --palette-purple-700: rgb(123, 31, 162);
  --palette-purple-800: rgb(106, 27, 154);
  --palette-purple-900: rgb(74, 20, 140);
  --palette-purple-a100: rgb(234, 128, 252);
  --palette-purple-a200: rgb(224, 64, 251);
  --palette-purple-a400: rgb(213, 0, 249);
  --palette-purple-a700: rgb(170, 0, 255);
  --palette-deep-purple-50: rgb(237, 231, 246);
  --palette-deep-purple-100: rgb(209, 196, 233);
  --palette-deep-purple-200: rgb(179, 157, 219);
  --palette-deep-purple-300: rgb(149, 117, 205);
  --palette-deep-purple-400: rgb(126, 87, 194);
  --palette-deep-purple-500: rgb(103, 58, 183);
  --palette-deep-purple-600: rgb(94, 53, 177);
  --palette-deep-purple-700: rgb(81, 45, 168);
  --palette-deep-purple-800: rgb(69, 39, 160);
  --palette-deep-purple-900: rgb(49, 27, 146);
  --palette-deep-purple-a100: rgb(179, 136, 255);
  --palette-deep-purple-a200: rgb(124, 77, 255);
  --palette-deep-purple-a400: rgb(101, 31, 255);
  --palette-deep-purple-a700: rgb(98, 0, 234);
  --palette-indigo-50: rgb(232, 234, 246);
  --palette-indigo-100: rgb(197, 202, 233);
  --palette-indigo-200: rgb(159, 168, 218);
  --palette-indigo-300: rgb(121, 134, 203);
  --palette-indigo-400: rgb(92, 107, 192);
  --palette-indigo-500: rgb(63, 81, 181);
  --palette-indigo-600: rgb(57, 73, 171);
  --palette-indigo-700: rgb(48, 63, 159);
  --palette-indigo-800: rgb(40, 53, 147);
  --palette-indigo-900: rgb(26, 35, 126);
  --palette-indigo-a100: rgb(140, 158, 255);
  --palette-indigo-a200: rgb(83, 109, 254);
  --palette-indigo-a400: rgb(61, 90, 254);
  --palette-indigo-a700: rgb(48, 79, 254);
  --palette-blue-50: rgb(227, 242, 253);
  --palette-blue-100: rgb(187, 222, 251);
  --palette-blue-200: rgb(144, 202, 249);
  --palette-blue-300: rgb(100, 181, 246);
  --palette-blue-400: rgb(66, 165, 245);
  --palette-blue-500: rgb(33, 150, 243);
  --palette-blue-600: rgb(30, 136, 229);
  --palette-blue-700: rgb(25, 118, 210);
  --palette-blue-800: rgb(21, 101, 192);
  --palette-blue-900: rgb(13, 71, 161);
  --palette-blue-a100: rgb(130, 177, 255);
  --palette-blue-a200: rgb(68, 138, 255);
  --palette-blue-a400: rgb(41, 121, 255);
  --palette-blue-a700: rgb(41, 98, 255);
  --palette-light-blue-50: rgb(225, 245, 254);
  --palette-light-blue-100: rgb(179, 229, 252);
  --palette-light-blue-200: rgb(129, 212, 250);
  --palette-light-blue-300: rgb(79, 195, 247);
  --palette-light-blue-400: rgb(41, 182, 246);
  --palette-light-blue-500: rgb(3, 169, 244);
  --palette-light-blue-600: rgb(3, 155, 229);
  --palette-light-blue-700: rgb(2, 136, 209);
  --palette-light-blue-800: rgb(2, 119, 189);
  --palette-light-blue-900: rgb(1, 87, 155);
  --palette-light-blue-a100: rgb(128, 216, 255);
  --palette-light-blue-a200: rgb(64, 196, 255);
  --palette-light-blue-a400: rgb(0, 176, 255);
  --palette-light-blue-a700: rgb(0, 145, 234);
  --palette-cyan-50: rgb(224, 247, 250);
  --palette-cyan-100: rgb(178, 235, 242);
  --palette-cyan-200: rgb(128, 222, 234);
  --palette-cyan-300: rgb(77, 208, 225);
  --palette-cyan-400: rgb(38, 198, 218);
  --palette-cyan-500: rgb(0, 188, 212);
  --palette-cyan-600: rgb(0, 172, 193);
  --palette-cyan-700: rgb(0, 151, 167);
  --palette-cyan-800: rgb(0, 131, 143);
  --palette-cyan-900: rgb(0, 96, 100);
  --palette-cyan-a100: rgb(132, 255, 255);
  --palette-cyan-a200: rgb(24, 255, 255);
  --palette-cyan-a400: rgb(0, 229, 255);
  --palette-cyan-a700: rgb(0, 184, 212);
  --palette-teal-50: rgb(224, 242, 241);
  --palette-teal-100: rgb(178, 223, 219);
  --palette-teal-200: rgb(128, 203, 196);
  --palette-teal-300: rgb(77, 182, 172);
  --palette-teal-400: rgb(38, 166, 154);
  --palette-teal-500: rgb(0, 150, 136);
  --palette-teal-600: rgb(0, 137, 123);
  --palette-teal-700: rgb(0, 121, 107);
  --palette-teal-800: rgb(0, 105, 92);
  --palette-teal-900: rgb(0, 77, 64);
  --palette-teal-a100: rgb(167, 255, 235);
  --palette-teal-a200: rgb(100, 255, 218);
  --palette-teal-a400: rgb(29, 233, 182);
  --palette-teal-a700: rgb(0, 191, 165);
  --palette-green-50: rgb(232, 245, 233);
  --palette-green-100: rgb(200, 230, 201);
  --palette-green-200: rgb(165, 214, 167);
  --palette-green-300: rgb(129, 199, 132);
  --palette-green-400: rgb(102, 187, 106);
  --palette-green-500: rgb(76, 175, 80);
  --palette-green-600: rgb(67, 160, 71);
  --palette-green-700: rgb(56, 142, 60);
  --palette-green-800: rgb(46, 125, 50);
  --palette-green-900: rgb(27, 94, 32);
  --palette-green-a100: rgb(185, 246, 202);
  --palette-green-a200: rgb(105, 240, 174);
  --palette-green-a400: rgb(0, 230, 118);
  --palette-green-a700: rgb(0, 200, 83);
  --palette-light-green-50: rgb(241, 248, 233);
  --palette-light-green-100: rgb(220, 237, 200);
  --palette-light-green-200: rgb(197, 225, 165);
  --palette-light-green-300: rgb(174, 213, 129);
  --palette-light-green-400: rgb(156, 204, 101);
  --palette-light-green-500: rgb(139, 195, 74);
  --palette-light-green-600: rgb(124, 179, 66);
  --palette-light-green-700: rgb(104, 159, 56);
  --palette-light-green-800: rgb(85, 139, 47);
  --palette-light-green-900: rgb(51, 105, 30);
  --palette-light-green-a100: rgb(204, 255, 144);
  --palette-light-green-a200: rgb(178, 255, 89);
  --palette-light-green-a400: rgb(118, 255, 3);
  --palette-light-green-a700: rgb(100, 221, 23);
  --palette-lime-50: rgb(249, 251, 231);
  --palette-lime-100: rgb(240, 244, 195);
  --palette-lime-200: rgb(230, 238, 156);
  --palette-lime-300: rgb(220, 231, 117);
  --palette-lime-400: rgb(212, 225, 87);
  --palette-lime-500: rgb(205, 220, 57);
  --palette-lime-600: rgb(192, 202, 51);
  --palette-lime-700: rgb(175, 180, 43);
  --palette-lime-800: rgb(158, 157, 36);
  --palette-lime-900: rgb(130, 119, 23);
  --palette-lime-a100: rgb(244, 255, 129);
  --palette-lime-a200: rgb(238, 255, 65);
  --palette-lime-a400: rgb(198, 255, 0);
  --palette-lime-a700: rgb(174, 234, 0);
  --palette-yellow-50: rgb(255, 253, 231);
  --palette-yellow-100: rgb(255, 249, 196);
  --palette-yellow-200: rgb(255, 245, 157);
  --palette-yellow-300: rgb(255, 241, 118);
  --palette-yellow-400: rgb(255, 238, 88);
  --palette-yellow-500: rgb(255, 235, 59);
  --palette-yellow-600: rgb(253, 216, 53);
  --palette-yellow-700: rgb(251, 192, 45);
  --palette-yellow-800: rgb(249, 168, 37);
  --palette-yellow-900: rgb(245, 127, 23);
  --palette-yellow-a100: rgb(255, 255, 141);
  --palette-yellow-a200: rgb(255, 255, 0);
  --palette-yellow-a400: rgb(255, 234, 0);
  --palette-yellow-a700: rgb(255, 214, 0);
  --palette-amber-50: rgb(255, 248, 225);
  --palette-amber-100: rgb(255, 236, 179);
  --palette-amber-200: rgb(255, 224, 130);
  --palette-amber-300: rgb(255, 213, 79);
  --palette-amber-400: rgb(255, 202, 40);
  --palette-amber-500: rgb(255, 193, 7);
  --palette-amber-600: rgb(255, 179, 0);
  --palette-amber-700: rgb(255, 160, 0);
  --palette-amber-800: rgb(255, 143, 0);
  --palette-amber-900: rgb(255, 111, 0);
  --palette-amber-a100: rgb(255, 229, 127);
  --palette-amber-a200: rgb(255, 215, 64);
  --palette-amber-a400: rgb(255, 196, 0);
  --palette-amber-a700: rgb(255, 171, 0);
  --palette-orange-50: rgb(255, 243, 224);
  --palette-orange-100: rgb(255, 224, 178);
  --palette-orange-200: rgb(255, 204, 128);
  --palette-orange-300: rgb(255, 183, 77);
  --palette-orange-400: rgb(255, 167, 38);
  --palette-orange-500: rgb(255, 152, 0);
  --palette-orange-600: rgb(251, 140, 0);
  --palette-orange-700: rgb(245, 124, 0);
  --palette-orange-800: rgb(239, 108, 0);
  --palette-orange-900: rgb(230, 81, 0);
  --palette-orange-a100: rgb(255, 209, 128);
  --palette-orange-a200: rgb(255, 171, 64);
  --palette-orange-a400: rgb(255, 145, 0);
  --palette-orange-a700: rgb(255, 109, 0);
  --palette-deep-orange-50: rgb(251, 233, 231);
  --palette-deep-orange-100: rgb(255, 204, 188);
  --palette-deep-orange-200: rgb(255, 171, 145);
  --palette-deep-orange-300: rgb(255, 138, 101);
  --palette-deep-orange-400: rgb(255, 112, 67);
  --palette-deep-orange-500: rgb(255, 87, 34);
  --palette-deep-orange-600: rgb(244, 81, 30);
  --palette-deep-orange-700: rgb(230, 74, 25);
  --palette-deep-orange-800: rgb(216, 67, 21);
  --palette-deep-orange-900: rgb(191, 54, 12);
  --palette-deep-orange-a100: rgb(255, 158, 128);
  --palette-deep-orange-a200: rgb(255, 110, 64);
  --palette-deep-orange-a400: rgb(255, 61, 0);
  --palette-deep-orange-a700: rgb(221, 44, 0);
  --palette-brown-50: rgb(239, 235, 233);
  --palette-brown-100: rgb(215, 204, 200);
  --palette-brown-200: rgb(188, 170, 164);
  --palette-brown-300: rgb(161, 136, 127);
  --palette-brown-400: rgb(141, 110, 99);
  --palette-brown-500: rgb(121, 85, 72);
  --palette-brown-600: rgb(109, 76, 65);
  --palette-brown-700: rgb(93, 64, 55);
  --palette-brown-800: rgb(78, 52, 46);
  --palette-brown-900: rgb(62, 39, 35);
  --palette-grey-50: rgb(250, 250, 250);
  --palette-grey-100: rgb(245, 245, 245);
  --palette-grey-200: rgb(238, 238, 238);
  --palette-grey-300: rgb(224, 224, 224);
  --palette-grey-400: rgb(189, 189, 189);
  --palette-grey-500: rgb(158, 158, 158);
  --palette-grey-600: rgb(117, 117, 117);
  --palette-grey-700: rgb(97, 97, 97);
  --palette-grey-800: rgb(66, 66, 66);
  --palette-grey-900: rgb(33, 33, 33);
  --palette-blue-grey-50: rgb(236, 239, 241);
  --palette-blue-grey-100: rgb(207, 216, 220);
  --palette-blue-grey-200: rgb(176, 190, 197);
  --palette-blue-grey-300: rgb(144, 164, 174);
  --palette-blue-grey-400: rgb(120, 144, 156);
  --palette-blue-grey-500: rgb(96, 125, 139);
  --palette-blue-grey-600: rgb(84, 110, 122);
  --palette-blue-grey-700: rgb(69, 90, 100);
  --palette-blue-grey-800: rgb(55, 71, 79);
  --palette-blue-grey-900: rgb(38, 50, 56);
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-dark-contrast: var(--color-white);
  --color-light-contrast: var(--color-black);
}
:root {
  --color-divider: var(--palette-grey-200);
  --color-background: var(--color-white);
  --color-text: var(--palette-grey-900);
  --color-text-secondary: var(--palette-grey-600);
  --color-primary: var(--palette-indigo-500);
  --color-primary-dark: var(--palette-indigo-700);
  --color-accent: var(--palette-pink-a200);
  --color-accent-dark: var(--palette-pink-700);
  --color-primary-contrast: var(--color-dark-contrast);
  --color-accent-contrast: var(--color-dark-contrast);
  --unit: 10px;
  --preferred-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;
  --shadow-2p:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --shadow-3p:
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --shadow-4p:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-6p:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --shadow-8p:
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --shadow-16p:
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --shadow-key-umbra-opacity: 0.2;
  --shadow-key-penumbra-opacity: 0.14;
  --shadow-ambient-shadow-opacity: 0.12;
  --zdepth-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  --zdepth-shadow-2: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  --zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);
  --z-index-highest: 300;
  --z-index-higher: 200;
  --z-index-high: 100;
  --z-index-normal: 1;
  --z-index-low: -100;
  --z-index-lower: -200
}
:root {
  --input-padding: calc(2 * var(--unit));
  --input-field-padding: calc(0.8 * var(--unit));
  --input-field-font-size: calc(1.6 * var(--unit));
  --input-field-height: calc(var(--input-field-padding) * 2 + var(--input-field-font-size) * 1.4);
  --input-label-font-size: calc(1.2 * var(--unit));
  --input-focus-label-top: calc(0.6 * var(--unit));
  --input-text-background-color: transparent;
  --input-text-label-color: color(var(--color-black) a(26%));
  --input-text-bottom-border-color: color(var(--color-black) a(12%));
  --input-text-highlight-color: var(--color-primary);
  --input-text-disabled-color: var(--input-text-bottom-border-color);
  --input-text-disabled-text-color: var(--input-text-label-color);
  --input-text-error-color: rgb(222, 50, 38);
  --input-text-required-color: rgb(222, 50, 38);
  --input-underline-height: calc(2 * var(--unit));
  --input-icon-font-size: calc(2.4 * var(--unit));
  --input-icon-size: calc(2 * var(--input-icon-font-size));
  --input-icon-offset: calc(1.6 * var(--unit));
  --input-icon-right-space: calc(2 * var(--unit));
  --input-chevron-offset: calc(0.8 * var(--unit));
  --input-hint-opacity: 1;
}
:root {
  --dropdown-value-border-size: calc(var(--input-field-height) / 7);
  --dropdown-color-white: var(--color-white);
  --dropdown-color-primary: var(--color-primary);
  --dropdown-color-primary-contrast: var(--color-primary-contrast);
  --dropdown-color-disabled: color(var(--color-black) a(26%));
  --dropdown-value-hover-background: var(--palette-grey-200);
  --dropdown-overflow-max-height: 45vh;
  --dropdown-value-border-radius: calc(0.2 * var(--unit));
}
.dropdown {
  position: relative;
  box-sizing: border-box;
  font-family: var(--preferred-font);
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%
}
.dropdown *,
    .dropdown *::after,
    .dropdown *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  -webkit-touch-callout: none;
}
.dropdown:not(.active) > .values {
  max-height: 0;
  visibility: hidden;
}
.dropdown.active > .label,
    .dropdown.active > .value {
  opacity: 0.5;
}
.dropdown.active > .values {
  box-shadow: var(--zdepth-shadow-1);
  max-height: var(--dropdown-overflow-max-height);
  visibility: visible;
}
.dropdown:not(.up) > .values {
  bottom: auto;
  top: 0;
}
.dropdown.up > .values {
  bottom: 0;
  top: auto;
}
.dropdown.disabled {
  cursor: normal;
  pointer-events: none;
}
.value > input {
  cursor: pointer;
}
.value::after {
  border-left: var(--dropdown-value-border-size) solid transparent;
  border-right: var(--dropdown-value-border-size) solid transparent;
  border-top: var(--dropdown-value-border-size) solid var(--input-text-bottom-border-color);
  content: '';
  height: 0;
  pointer-events: none;
  position: absolute;
  right: var(--input-chevron-offset);
  top: 50%;
  transition: transform var(--animation-duration) var(--animation-curve-default);
  width: 0;
}
.field {
  cursor: pointer;
  padding: var(--input-padding) 0;
  position: relative
}
.field.errored {
  padding-bottom: 0;
}
.field.errored > .label {
  color: var(--input-text-error-color);
}
.field.errored > .templateValue {
  border-bottom: 1px solid var(--input-text-error-color);
}
.field.errored > .label > .required {
  color: var(--input-text-error-color);
}
.field.disabled {
  cursor: normal;
  pointer-events: none;
}
.field.disabled > .templateValue {
  border-bottom-style: dotted;
  opacity: 0.7;
}
.templateValue {
  background-color: var(--input-text-background-color);
  border-bottom: 1px solid var(--input-text-bottom-border-color);
  color: var(--color-text);
  min-height: var(--input-field-height);
  padding: var(--input-field-padding) 0;
  position: relative;
}
.label {
  color: var(--input-text-label-color);
  font-size: var(--input-label-font-size);
  left: 0;
  line-height: var(--input-field-font-size);
  position: absolute;
  top: var(--input-focus-label-top)
}
.label .required {
  color: var(--input-text-error-color);
}
.error {
  color: var(--input-text-error-color);
  font-size: var(--input-label-font-size);
  line-height: var(--input-underline-height);
  margin-bottom: calc(-1 * var(--input-underline-height));
}
.values {
  background-color: var(--dropdown-color-white);
  border-radius: var(--dropdown-value-border-radius);
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  transition-duration: var(--animation-duration);
  transition-property: max-height, box-shadow;
  transition-timing-function: var(--animation-curve-default);
  width: 100%;
  z-index: var(--z-index-high)
}
.values > * {
  cursor: pointer;
  overflow: hidden;
  padding: var(--unit);
  position: relative;
}
.values > *:hover:not(.disabled) {
  background-color: var(--dropdown-value-hover-background);
}
.values > *.selected {
  color: var(--dropdown-color-primary);
}
.values > *.disabled {
  color: var(--dropdown-color-disabled);
  cursor: not-allowed;
}
.values::-webkit-scrollbar {
  height: 0;
  width: 0;
}

:root {
  --color-divider: var(--palette-grey-200);
  --color-background: var(--color-white);
  --color-text: var(--palette-grey-900);
  --color-text-secondary: var(--palette-grey-600);
  --color-primary: var(--palette-indigo-500);
  --color-primary-dark: var(--palette-indigo-700);
  --color-accent: var(--palette-pink-a200);
  --color-accent-dark: var(--palette-pink-700);
  --color-primary-contrast: var(--color-dark-contrast);
  --color-accent-contrast: var(--color-dark-contrast);
  --unit: 10px;
  --preferred-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;
  --shadow-2p:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --shadow-3p:
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --shadow-4p:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-6p:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --shadow-8p:
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --shadow-16p:
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --shadow-key-umbra-opacity: 0.2;
  --shadow-key-penumbra-opacity: 0.14;
  --shadow-ambient-shadow-opacity: 0.12;
  --zdepth-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  --zdepth-shadow-2: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  --zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);
  --z-index-highest: 300;
  --z-index-higher: 200;
  --z-index-high: 100;
  --z-index-normal: 1;
  --z-index-low: -100;
  --z-index-lower: -200
}
:root {
  --ripple-duration: 800ms;
  --ripple-final-opacity: 0.3;
  --ripple-size: calc(15 * var(--unit));
}
.rippleWrapper {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--z-index-normal);
}
.ripple {
  background-color: currentColor;
  border-radius: 50%;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transition-duration: var(--ripple-duration);
  z-index: var(--z-index-high)
}
.ripple.rippleRestarting {
  opacity: var(--ripple-final-opacity);
  transition-property: none;
}
.ripple.rippleActive {
  opacity: var(--ripple-final-opacity);
  transition-property: transform;
}
.ripple:not(.rippleActive):not(.rippleRestarting) {
  opacity: 0;
  transition-property: opacity, transform;
}

:root {
  --palette-red-50: rgb(255, 235, 238);
  --palette-red-100: rgb(255, 205, 210);
  --palette-red-200: rgb(239, 154, 154);
  --palette-red-300: rgb(229, 115, 115);
  --palette-red-400: rgb(239, 83, 80);
  --palette-red-500: rgb(244, 67, 54);
  --palette-red-600: rgb(229, 57, 53);
  --palette-red-700: rgb(211, 47, 47);
  --palette-red-800: rgb(198, 40, 40);
  --palette-red-900: rgb(183, 28, 28);
  --palette-red-a100: rgb(255, 138, 128);
  --palette-red-a200: rgb(255, 82, 82);
  --palette-red-a400: rgb(255, 23, 68);
  --palette-red-a700: rgb(213, 0, 0);
  --palette-pink-50: rgb(252, 228, 236);
  --palette-pink-100: rgb(248, 187, 208);
  --palette-pink-200: rgb(244, 143, 177);
  --palette-pink-300: rgb(240, 98, 146);
  --palette-pink-400: rgb(236, 64, 122);
  --palette-pink-500: rgb(233, 30, 99);
  --palette-pink-600: rgb(216, 27, 96);
  --palette-pink-700: rgb(194, 24, 91);
  --palette-pink-800: rgb(173, 20, 87);
  --palette-pink-900: rgb(136, 14, 79);
  --palette-pink-a100: rgb(255, 128, 171);
  --palette-pink-a200: rgb(255, 64, 129);
  --palette-pink-a400: rgb(245, 0, 87);
  --palette-pink-a700: rgb(197, 17, 98);
  --palette-purple-50: rgb(243, 229, 245);
  --palette-purple-100: rgb(225, 190, 231);
  --palette-purple-200: rgb(206, 147, 216);
  --palette-purple-300: rgb(186, 104, 200);
  --palette-purple-400: rgb(171, 71, 188);
  --palette-purple-500: rgb(156, 39, 176);
  --palette-purple-600: rgb(142, 36, 170);
  --palette-purple-700: rgb(123, 31, 162);
  --palette-purple-800: rgb(106, 27, 154);
  --palette-purple-900: rgb(74, 20, 140);
  --palette-purple-a100: rgb(234, 128, 252);
  --palette-purple-a200: rgb(224, 64, 251);
  --palette-purple-a400: rgb(213, 0, 249);
  --palette-purple-a700: rgb(170, 0, 255);
  --palette-deep-purple-50: rgb(237, 231, 246);
  --palette-deep-purple-100: rgb(209, 196, 233);
  --palette-deep-purple-200: rgb(179, 157, 219);
  --palette-deep-purple-300: rgb(149, 117, 205);
  --palette-deep-purple-400: rgb(126, 87, 194);
  --palette-deep-purple-500: rgb(103, 58, 183);
  --palette-deep-purple-600: rgb(94, 53, 177);
  --palette-deep-purple-700: rgb(81, 45, 168);
  --palette-deep-purple-800: rgb(69, 39, 160);
  --palette-deep-purple-900: rgb(49, 27, 146);
  --palette-deep-purple-a100: rgb(179, 136, 255);
  --palette-deep-purple-a200: rgb(124, 77, 255);
  --palette-deep-purple-a400: rgb(101, 31, 255);
  --palette-deep-purple-a700: rgb(98, 0, 234);
  --palette-indigo-50: rgb(232, 234, 246);
  --palette-indigo-100: rgb(197, 202, 233);
  --palette-indigo-200: rgb(159, 168, 218);
  --palette-indigo-300: rgb(121, 134, 203);
  --palette-indigo-400: rgb(92, 107, 192);
  --palette-indigo-500: rgb(63, 81, 181);
  --palette-indigo-600: rgb(57, 73, 171);
  --palette-indigo-700: rgb(48, 63, 159);
  --palette-indigo-800: rgb(40, 53, 147);
  --palette-indigo-900: rgb(26, 35, 126);
  --palette-indigo-a100: rgb(140, 158, 255);
  --palette-indigo-a200: rgb(83, 109, 254);
  --palette-indigo-a400: rgb(61, 90, 254);
  --palette-indigo-a700: rgb(48, 79, 254);
  --palette-blue-50: rgb(227, 242, 253);
  --palette-blue-100: rgb(187, 222, 251);
  --palette-blue-200: rgb(144, 202, 249);
  --palette-blue-300: rgb(100, 181, 246);
  --palette-blue-400: rgb(66, 165, 245);
  --palette-blue-500: rgb(33, 150, 243);
  --palette-blue-600: rgb(30, 136, 229);
  --palette-blue-700: rgb(25, 118, 210);
  --palette-blue-800: rgb(21, 101, 192);
  --palette-blue-900: rgb(13, 71, 161);
  --palette-blue-a100: rgb(130, 177, 255);
  --palette-blue-a200: rgb(68, 138, 255);
  --palette-blue-a400: rgb(41, 121, 255);
  --palette-blue-a700: rgb(41, 98, 255);
  --palette-light-blue-50: rgb(225, 245, 254);
  --palette-light-blue-100: rgb(179, 229, 252);
  --palette-light-blue-200: rgb(129, 212, 250);
  --palette-light-blue-300: rgb(79, 195, 247);
  --palette-light-blue-400: rgb(41, 182, 246);
  --palette-light-blue-500: rgb(3, 169, 244);
  --palette-light-blue-600: rgb(3, 155, 229);
  --palette-light-blue-700: rgb(2, 136, 209);
  --palette-light-blue-800: rgb(2, 119, 189);
  --palette-light-blue-900: rgb(1, 87, 155);
  --palette-light-blue-a100: rgb(128, 216, 255);
  --palette-light-blue-a200: rgb(64, 196, 255);
  --palette-light-blue-a400: rgb(0, 176, 255);
  --palette-light-blue-a700: rgb(0, 145, 234);
  --palette-cyan-50: rgb(224, 247, 250);
  --palette-cyan-100: rgb(178, 235, 242);
  --palette-cyan-200: rgb(128, 222, 234);
  --palette-cyan-300: rgb(77, 208, 225);
  --palette-cyan-400: rgb(38, 198, 218);
  --palette-cyan-500: rgb(0, 188, 212);
  --palette-cyan-600: rgb(0, 172, 193);
  --palette-cyan-700: rgb(0, 151, 167);
  --palette-cyan-800: rgb(0, 131, 143);
  --palette-cyan-900: rgb(0, 96, 100);
  --palette-cyan-a100: rgb(132, 255, 255);
  --palette-cyan-a200: rgb(24, 255, 255);
  --palette-cyan-a400: rgb(0, 229, 255);
  --palette-cyan-a700: rgb(0, 184, 212);
  --palette-teal-50: rgb(224, 242, 241);
  --palette-teal-100: rgb(178, 223, 219);
  --palette-teal-200: rgb(128, 203, 196);
  --palette-teal-300: rgb(77, 182, 172);
  --palette-teal-400: rgb(38, 166, 154);
  --palette-teal-500: rgb(0, 150, 136);
  --palette-teal-600: rgb(0, 137, 123);
  --palette-teal-700: rgb(0, 121, 107);
  --palette-teal-800: rgb(0, 105, 92);
  --palette-teal-900: rgb(0, 77, 64);
  --palette-teal-a100: rgb(167, 255, 235);
  --palette-teal-a200: rgb(100, 255, 218);
  --palette-teal-a400: rgb(29, 233, 182);
  --palette-teal-a700: rgb(0, 191, 165);
  --palette-green-50: rgb(232, 245, 233);
  --palette-green-100: rgb(200, 230, 201);
  --palette-green-200: rgb(165, 214, 167);
  --palette-green-300: rgb(129, 199, 132);
  --palette-green-400: rgb(102, 187, 106);
  --palette-green-500: rgb(76, 175, 80);
  --palette-green-600: rgb(67, 160, 71);
  --palette-green-700: rgb(56, 142, 60);
  --palette-green-800: rgb(46, 125, 50);
  --palette-green-900: rgb(27, 94, 32);
  --palette-green-a100: rgb(185, 246, 202);
  --palette-green-a200: rgb(105, 240, 174);
  --palette-green-a400: rgb(0, 230, 118);
  --palette-green-a700: rgb(0, 200, 83);
  --palette-light-green-50: rgb(241, 248, 233);
  --palette-light-green-100: rgb(220, 237, 200);
  --palette-light-green-200: rgb(197, 225, 165);
  --palette-light-green-300: rgb(174, 213, 129);
  --palette-light-green-400: rgb(156, 204, 101);
  --palette-light-green-500: rgb(139, 195, 74);
  --palette-light-green-600: rgb(124, 179, 66);
  --palette-light-green-700: rgb(104, 159, 56);
  --palette-light-green-800: rgb(85, 139, 47);
  --palette-light-green-900: rgb(51, 105, 30);
  --palette-light-green-a100: rgb(204, 255, 144);
  --palette-light-green-a200: rgb(178, 255, 89);
  --palette-light-green-a400: rgb(118, 255, 3);
  --palette-light-green-a700: rgb(100, 221, 23);
  --palette-lime-50: rgb(249, 251, 231);
  --palette-lime-100: rgb(240, 244, 195);
  --palette-lime-200: rgb(230, 238, 156);
  --palette-lime-300: rgb(220, 231, 117);
  --palette-lime-400: rgb(212, 225, 87);
  --palette-lime-500: rgb(205, 220, 57);
  --palette-lime-600: rgb(192, 202, 51);
  --palette-lime-700: rgb(175, 180, 43);
  --palette-lime-800: rgb(158, 157, 36);
  --palette-lime-900: rgb(130, 119, 23);
  --palette-lime-a100: rgb(244, 255, 129);
  --palette-lime-a200: rgb(238, 255, 65);
  --palette-lime-a400: rgb(198, 255, 0);
  --palette-lime-a700: rgb(174, 234, 0);
  --palette-yellow-50: rgb(255, 253, 231);
  --palette-yellow-100: rgb(255, 249, 196);
  --palette-yellow-200: rgb(255, 245, 157);
  --palette-yellow-300: rgb(255, 241, 118);
  --palette-yellow-400: rgb(255, 238, 88);
  --palette-yellow-500: rgb(255, 235, 59);
  --palette-yellow-600: rgb(253, 216, 53);
  --palette-yellow-700: rgb(251, 192, 45);
  --palette-yellow-800: rgb(249, 168, 37);
  --palette-yellow-900: rgb(245, 127, 23);
  --palette-yellow-a100: rgb(255, 255, 141);
  --palette-yellow-a200: rgb(255, 255, 0);
  --palette-yellow-a400: rgb(255, 234, 0);
  --palette-yellow-a700: rgb(255, 214, 0);
  --palette-amber-50: rgb(255, 248, 225);
  --palette-amber-100: rgb(255, 236, 179);
  --palette-amber-200: rgb(255, 224, 130);
  --palette-amber-300: rgb(255, 213, 79);
  --palette-amber-400: rgb(255, 202, 40);
  --palette-amber-500: rgb(255, 193, 7);
  --palette-amber-600: rgb(255, 179, 0);
  --palette-amber-700: rgb(255, 160, 0);
  --palette-amber-800: rgb(255, 143, 0);
  --palette-amber-900: rgb(255, 111, 0);
  --palette-amber-a100: rgb(255, 229, 127);
  --palette-amber-a200: rgb(255, 215, 64);
  --palette-amber-a400: rgb(255, 196, 0);
  --palette-amber-a700: rgb(255, 171, 0);
  --palette-orange-50: rgb(255, 243, 224);
  --palette-orange-100: rgb(255, 224, 178);
  --palette-orange-200: rgb(255, 204, 128);
  --palette-orange-300: rgb(255, 183, 77);
  --palette-orange-400: rgb(255, 167, 38);
  --palette-orange-500: rgb(255, 152, 0);
  --palette-orange-600: rgb(251, 140, 0);
  --palette-orange-700: rgb(245, 124, 0);
  --palette-orange-800: rgb(239, 108, 0);
  --palette-orange-900: rgb(230, 81, 0);
  --palette-orange-a100: rgb(255, 209, 128);
  --palette-orange-a200: rgb(255, 171, 64);
  --palette-orange-a400: rgb(255, 145, 0);
  --palette-orange-a700: rgb(255, 109, 0);
  --palette-deep-orange-50: rgb(251, 233, 231);
  --palette-deep-orange-100: rgb(255, 204, 188);
  --palette-deep-orange-200: rgb(255, 171, 145);
  --palette-deep-orange-300: rgb(255, 138, 101);
  --palette-deep-orange-400: rgb(255, 112, 67);
  --palette-deep-orange-500: rgb(255, 87, 34);
  --palette-deep-orange-600: rgb(244, 81, 30);
  --palette-deep-orange-700: rgb(230, 74, 25);
  --palette-deep-orange-800: rgb(216, 67, 21);
  --palette-deep-orange-900: rgb(191, 54, 12);
  --palette-deep-orange-a100: rgb(255, 158, 128);
  --palette-deep-orange-a200: rgb(255, 110, 64);
  --palette-deep-orange-a400: rgb(255, 61, 0);
  --palette-deep-orange-a700: rgb(221, 44, 0);
  --palette-brown-50: rgb(239, 235, 233);
  --palette-brown-100: rgb(215, 204, 200);
  --palette-brown-200: rgb(188, 170, 164);
  --palette-brown-300: rgb(161, 136, 127);
  --palette-brown-400: rgb(141, 110, 99);
  --palette-brown-500: rgb(121, 85, 72);
  --palette-brown-600: rgb(109, 76, 65);
  --palette-brown-700: rgb(93, 64, 55);
  --palette-brown-800: rgb(78, 52, 46);
  --palette-brown-900: rgb(62, 39, 35);
  --palette-grey-50: rgb(250, 250, 250);
  --palette-grey-100: rgb(245, 245, 245);
  --palette-grey-200: rgb(238, 238, 238);
  --palette-grey-300: rgb(224, 224, 224);
  --palette-grey-400: rgb(189, 189, 189);
  --palette-grey-500: rgb(158, 158, 158);
  --palette-grey-600: rgb(117, 117, 117);
  --palette-grey-700: rgb(97, 97, 97);
  --palette-grey-800: rgb(66, 66, 66);
  --palette-grey-900: rgb(33, 33, 33);
  --palette-blue-grey-50: rgb(236, 239, 241);
  --palette-blue-grey-100: rgb(207, 216, 220);
  --palette-blue-grey-200: rgb(176, 190, 197);
  --palette-blue-grey-300: rgb(144, 164, 174);
  --palette-blue-grey-400: rgb(120, 144, 156);
  --palette-blue-grey-500: rgb(96, 125, 139);
  --palette-blue-grey-600: rgb(84, 110, 122);
  --palette-blue-grey-700: rgb(69, 90, 100);
  --palette-blue-grey-800: rgb(55, 71, 79);
  --palette-blue-grey-900: rgb(38, 50, 56);
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-dark-contrast: var(--color-white);
  --color-light-contrast: var(--color-black);
}
:root {
  --color-divider: var(--palette-grey-200);
  --color-background: var(--color-white);
  --color-text: var(--palette-grey-900);
  --color-text-secondary: var(--palette-grey-600);
  --color-primary: var(--palette-indigo-500);
  --color-primary-dark: var(--palette-indigo-700);
  --color-accent: var(--palette-pink-a200);
  --color-accent-dark: var(--palette-pink-700);
  --color-primary-contrast: var(--color-dark-contrast);
  --color-accent-contrast: var(--color-dark-contrast);
  --unit: 10px;
  --preferred-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;
  --shadow-2p:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --shadow-3p:
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --shadow-4p:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-6p:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --shadow-8p:
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --shadow-16p:
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --shadow-key-umbra-opacity: 0.2;
  --shadow-key-penumbra-opacity: 0.14;
  --shadow-ambient-shadow-opacity: 0.12;
  --zdepth-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  --zdepth-shadow-2: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  --zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);
  --z-index-highest: 300;
  --z-index-higher: 200;
  --z-index-high: 100;
  --z-index-normal: 1;
  --z-index-low: -100;
  --z-index-lower: -200
}
:root {
  --checkbox-color: var(--color-primary);
  --checkbox-disabled-color: color(var(--color-black) a(26%));
  --checkbox-field-margin-bottom: calc(1.5 * var(--unit));
  --checkbox-focus-checked-color: color(var(--color-primary) a(26%));
  --checkbox-ripple-duration: 650ms;
  --checkbox-size: calc(1.8 * var(--unit));
  --checkbox-focus-color: color(var(--color-black) a(1%));
  --checkbox-focus-size: calc(var(--checkbox-size) * 2.3);
  --checkbox-text-color: var(--color-black);
  --checkbox-text-font-size: var(--font-size-small);
  --checkbox-total-height: calc(1.8 * var(--unit));
  --checkbox-transition-duration: 0.2s;
}
.field {
  display: block;
  height: var(--checkbox-size);
  margin-bottom: var(--checkbox-field-margin-bottom);
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  font-family: var(--preferred-font);
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%
}
.field *,
    .field *::after,
    .field *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  -webkit-touch-callout: none;
}
.field .ripple {
  background-color: var(--checkbox-color);
  opacity: 0.3;
  transition-duration: var(--checkbox-ripple-duration);
}
.text {
  color: var(--checkbox-text-color);
  display: inline-block;
  font-size: var(--checkbox-text-font-size);
  line-height: var(--checkbox-size);
  padding-left: var(--unit);
  vertical-align: top;
  white-space: nowrap;
}
.input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0
}
.input:focus ~ .check::before {
  background-color: var(--checkbox-focus-color);
  border-radius: 50%;
  content: '';
  height: var(--checkbox-focus-size);
  left: 50%;
  margin-left: calc(-1 * var(--checkbox-focus-size) / 2);
  margin-top: calc(-1 * var(--checkbox-focus-size) / 2);
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: var(--checkbox-focus-size);
}
.input:focus ~ .check.checked::before {
  background-color: var(--checkbox-focus-checked-color);
}
.check {
  border-color: var(--checkbox-text-color);
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: inline-block;
  height: var(--checkbox-size);
  position: relative;
  transition-duration: var(--checkbox-transition-duration);
  transition-property: background-color;
  transition-timing-function: var(--animation-curve-default);
  vertical-align: top;
  width: var(--checkbox-size);
  box-sizing: border-box;
  font-family: var(--preferred-font);
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%
}
.check *,
    .check *::after,
    .check *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  -webkit-touch-callout: none;
}
.check.checked {
  background-color: var(--checkbox-color);
  border-color: var(--checkbox-color);
}
.check.checked::after {
  animation: checkmark-expand 140ms ease-out forwards;
  border-bottom-width: 2px;
  border-color: var(--color-background);
  border-left: 0;
  border-right-width: 2px;
  border-style: solid;
  border-top: 0;
  content: '';
  height: calc(1.2 * var(--unit));
  left: calc(0.4 * var(--unit));
  position: absolute;
  top: calc(-0.1 * var(--unit));
  transform: rotate(45deg);
  width: calc(0.7 * var(--unit));
}
.disabled > .text {
  color: var(--checkbox-disabled-color);
}
.disabled > .check {
  border-color: var(--checkbox-disabled-color);
  cursor: auto;
}
.disabled > .check.checked {
  background-color: var(--checkbox-disabled-color);
  border-color: transparent;
  cursor: auto;
}
@keyframes checkmark-expand {
  0% {
    height: 0;
    left: calc(0.6 * var(--unit));
    top: calc(0.9 * var(--unit));
    width: 0;
  }

  100% {
    height: calc(1.2 * var(--unit));
    left: calc(0.4 * var(--unit));
    top: calc(-0.1 * var(--unit));
    width: calc(0.7 * var(--unit));
  }
}

:root {
  --palette-red-50: rgb(255, 235, 238);
  --palette-red-100: rgb(255, 205, 210);
  --palette-red-200: rgb(239, 154, 154);
  --palette-red-300: rgb(229, 115, 115);
  --palette-red-400: rgb(239, 83, 80);
  --palette-red-500: rgb(244, 67, 54);
  --palette-red-600: rgb(229, 57, 53);
  --palette-red-700: rgb(211, 47, 47);
  --palette-red-800: rgb(198, 40, 40);
  --palette-red-900: rgb(183, 28, 28);
  --palette-red-a100: rgb(255, 138, 128);
  --palette-red-a200: rgb(255, 82, 82);
  --palette-red-a400: rgb(255, 23, 68);
  --palette-red-a700: rgb(213, 0, 0);
  --palette-pink-50: rgb(252, 228, 236);
  --palette-pink-100: rgb(248, 187, 208);
  --palette-pink-200: rgb(244, 143, 177);
  --palette-pink-300: rgb(240, 98, 146);
  --palette-pink-400: rgb(236, 64, 122);
  --palette-pink-500: rgb(233, 30, 99);
  --palette-pink-600: rgb(216, 27, 96);
  --palette-pink-700: rgb(194, 24, 91);
  --palette-pink-800: rgb(173, 20, 87);
  --palette-pink-900: rgb(136, 14, 79);
  --palette-pink-a100: rgb(255, 128, 171);
  --palette-pink-a200: rgb(255, 64, 129);
  --palette-pink-a400: rgb(245, 0, 87);
  --palette-pink-a700: rgb(197, 17, 98);
  --palette-purple-50: rgb(243, 229, 245);
  --palette-purple-100: rgb(225, 190, 231);
  --palette-purple-200: rgb(206, 147, 216);
  --palette-purple-300: rgb(186, 104, 200);
  --palette-purple-400: rgb(171, 71, 188);
  --palette-purple-500: rgb(156, 39, 176);
  --palette-purple-600: rgb(142, 36, 170);
  --palette-purple-700: rgb(123, 31, 162);
  --palette-purple-800: rgb(106, 27, 154);
  --palette-purple-900: rgb(74, 20, 140);
  --palette-purple-a100: rgb(234, 128, 252);
  --palette-purple-a200: rgb(224, 64, 251);
  --palette-purple-a400: rgb(213, 0, 249);
  --palette-purple-a700: rgb(170, 0, 255);
  --palette-deep-purple-50: rgb(237, 231, 246);
  --palette-deep-purple-100: rgb(209, 196, 233);
  --palette-deep-purple-200: rgb(179, 157, 219);
  --palette-deep-purple-300: rgb(149, 117, 205);
  --palette-deep-purple-400: rgb(126, 87, 194);
  --palette-deep-purple-500: rgb(103, 58, 183);
  --palette-deep-purple-600: rgb(94, 53, 177);
  --palette-deep-purple-700: rgb(81, 45, 168);
  --palette-deep-purple-800: rgb(69, 39, 160);
  --palette-deep-purple-900: rgb(49, 27, 146);
  --palette-deep-purple-a100: rgb(179, 136, 255);
  --palette-deep-purple-a200: rgb(124, 77, 255);
  --palette-deep-purple-a400: rgb(101, 31, 255);
  --palette-deep-purple-a700: rgb(98, 0, 234);
  --palette-indigo-50: rgb(232, 234, 246);
  --palette-indigo-100: rgb(197, 202, 233);
  --palette-indigo-200: rgb(159, 168, 218);
  --palette-indigo-300: rgb(121, 134, 203);
  --palette-indigo-400: rgb(92, 107, 192);
  --palette-indigo-500: rgb(63, 81, 181);
  --palette-indigo-600: rgb(57, 73, 171);
  --palette-indigo-700: rgb(48, 63, 159);
  --palette-indigo-800: rgb(40, 53, 147);
  --palette-indigo-900: rgb(26, 35, 126);
  --palette-indigo-a100: rgb(140, 158, 255);
  --palette-indigo-a200: rgb(83, 109, 254);
  --palette-indigo-a400: rgb(61, 90, 254);
  --palette-indigo-a700: rgb(48, 79, 254);
  --palette-blue-50: rgb(227, 242, 253);
  --palette-blue-100: rgb(187, 222, 251);
  --palette-blue-200: rgb(144, 202, 249);
  --palette-blue-300: rgb(100, 181, 246);
  --palette-blue-400: rgb(66, 165, 245);
  --palette-blue-500: rgb(33, 150, 243);
  --palette-blue-600: rgb(30, 136, 229);
  --palette-blue-700: rgb(25, 118, 210);
  --palette-blue-800: rgb(21, 101, 192);
  --palette-blue-900: rgb(13, 71, 161);
  --palette-blue-a100: rgb(130, 177, 255);
  --palette-blue-a200: rgb(68, 138, 255);
  --palette-blue-a400: rgb(41, 121, 255);
  --palette-blue-a700: rgb(41, 98, 255);
  --palette-light-blue-50: rgb(225, 245, 254);
  --palette-light-blue-100: rgb(179, 229, 252);
  --palette-light-blue-200: rgb(129, 212, 250);
  --palette-light-blue-300: rgb(79, 195, 247);
  --palette-light-blue-400: rgb(41, 182, 246);
  --palette-light-blue-500: rgb(3, 169, 244);
  --palette-light-blue-600: rgb(3, 155, 229);
  --palette-light-blue-700: rgb(2, 136, 209);
  --palette-light-blue-800: rgb(2, 119, 189);
  --palette-light-blue-900: rgb(1, 87, 155);
  --palette-light-blue-a100: rgb(128, 216, 255);
  --palette-light-blue-a200: rgb(64, 196, 255);
  --palette-light-blue-a400: rgb(0, 176, 255);
  --palette-light-blue-a700: rgb(0, 145, 234);
  --palette-cyan-50: rgb(224, 247, 250);
  --palette-cyan-100: rgb(178, 235, 242);
  --palette-cyan-200: rgb(128, 222, 234);
  --palette-cyan-300: rgb(77, 208, 225);
  --palette-cyan-400: rgb(38, 198, 218);
  --palette-cyan-500: rgb(0, 188, 212);
  --palette-cyan-600: rgb(0, 172, 193);
  --palette-cyan-700: rgb(0, 151, 167);
  --palette-cyan-800: rgb(0, 131, 143);
  --palette-cyan-900: rgb(0, 96, 100);
  --palette-cyan-a100: rgb(132, 255, 255);
  --palette-cyan-a200: rgb(24, 255, 255);
  --palette-cyan-a400: rgb(0, 229, 255);
  --palette-cyan-a700: rgb(0, 184, 212);
  --palette-teal-50: rgb(224, 242, 241);
  --palette-teal-100: rgb(178, 223, 219);
  --palette-teal-200: rgb(128, 203, 196);
  --palette-teal-300: rgb(77, 182, 172);
  --palette-teal-400: rgb(38, 166, 154);
  --palette-teal-500: rgb(0, 150, 136);
  --palette-teal-600: rgb(0, 137, 123);
  --palette-teal-700: rgb(0, 121, 107);
  --palette-teal-800: rgb(0, 105, 92);
  --palette-teal-900: rgb(0, 77, 64);
  --palette-teal-a100: rgb(167, 255, 235);
  --palette-teal-a200: rgb(100, 255, 218);
  --palette-teal-a400: rgb(29, 233, 182);
  --palette-teal-a700: rgb(0, 191, 165);
  --palette-green-50: rgb(232, 245, 233);
  --palette-green-100: rgb(200, 230, 201);
  --palette-green-200: rgb(165, 214, 167);
  --palette-green-300: rgb(129, 199, 132);
  --palette-green-400: rgb(102, 187, 106);
  --palette-green-500: rgb(76, 175, 80);
  --palette-green-600: rgb(67, 160, 71);
  --palette-green-700: rgb(56, 142, 60);
  --palette-green-800: rgb(46, 125, 50);
  --palette-green-900: rgb(27, 94, 32);
  --palette-green-a100: rgb(185, 246, 202);
  --palette-green-a200: rgb(105, 240, 174);
  --palette-green-a400: rgb(0, 230, 118);
  --palette-green-a700: rgb(0, 200, 83);
  --palette-light-green-50: rgb(241, 248, 233);
  --palette-light-green-100: rgb(220, 237, 200);
  --palette-light-green-200: rgb(197, 225, 165);
  --palette-light-green-300: rgb(174, 213, 129);
  --palette-light-green-400: rgb(156, 204, 101);
  --palette-light-green-500: rgb(139, 195, 74);
  --palette-light-green-600: rgb(124, 179, 66);
  --palette-light-green-700: rgb(104, 159, 56);
  --palette-light-green-800: rgb(85, 139, 47);
  --palette-light-green-900: rgb(51, 105, 30);
  --palette-light-green-a100: rgb(204, 255, 144);
  --palette-light-green-a200: rgb(178, 255, 89);
  --palette-light-green-a400: rgb(118, 255, 3);
  --palette-light-green-a700: rgb(100, 221, 23);
  --palette-lime-50: rgb(249, 251, 231);
  --palette-lime-100: rgb(240, 244, 195);
  --palette-lime-200: rgb(230, 238, 156);
  --palette-lime-300: rgb(220, 231, 117);
  --palette-lime-400: rgb(212, 225, 87);
  --palette-lime-500: rgb(205, 220, 57);
  --palette-lime-600: rgb(192, 202, 51);
  --palette-lime-700: rgb(175, 180, 43);
  --palette-lime-800: rgb(158, 157, 36);
  --palette-lime-900: rgb(130, 119, 23);
  --palette-lime-a100: rgb(244, 255, 129);
  --palette-lime-a200: rgb(238, 255, 65);
  --palette-lime-a400: rgb(198, 255, 0);
  --palette-lime-a700: rgb(174, 234, 0);
  --palette-yellow-50: rgb(255, 253, 231);
  --palette-yellow-100: rgb(255, 249, 196);
  --palette-yellow-200: rgb(255, 245, 157);
  --palette-yellow-300: rgb(255, 241, 118);
  --palette-yellow-400: rgb(255, 238, 88);
  --palette-yellow-500: rgb(255, 235, 59);
  --palette-yellow-600: rgb(253, 216, 53);
  --palette-yellow-700: rgb(251, 192, 45);
  --palette-yellow-800: rgb(249, 168, 37);
  --palette-yellow-900: rgb(245, 127, 23);
  --palette-yellow-a100: rgb(255, 255, 141);
  --palette-yellow-a200: rgb(255, 255, 0);
  --palette-yellow-a400: rgb(255, 234, 0);
  --palette-yellow-a700: rgb(255, 214, 0);
  --palette-amber-50: rgb(255, 248, 225);
  --palette-amber-100: rgb(255, 236, 179);
  --palette-amber-200: rgb(255, 224, 130);
  --palette-amber-300: rgb(255, 213, 79);
  --palette-amber-400: rgb(255, 202, 40);
  --palette-amber-500: rgb(255, 193, 7);
  --palette-amber-600: rgb(255, 179, 0);
  --palette-amber-700: rgb(255, 160, 0);
  --palette-amber-800: rgb(255, 143, 0);
  --palette-amber-900: rgb(255, 111, 0);
  --palette-amber-a100: rgb(255, 229, 127);
  --palette-amber-a200: rgb(255, 215, 64);
  --palette-amber-a400: rgb(255, 196, 0);
  --palette-amber-a700: rgb(255, 171, 0);
  --palette-orange-50: rgb(255, 243, 224);
  --palette-orange-100: rgb(255, 224, 178);
  --palette-orange-200: rgb(255, 204, 128);
  --palette-orange-300: rgb(255, 183, 77);
  --palette-orange-400: rgb(255, 167, 38);
  --palette-orange-500: rgb(255, 152, 0);
  --palette-orange-600: rgb(251, 140, 0);
  --palette-orange-700: rgb(245, 124, 0);
  --palette-orange-800: rgb(239, 108, 0);
  --palette-orange-900: rgb(230, 81, 0);
  --palette-orange-a100: rgb(255, 209, 128);
  --palette-orange-a200: rgb(255, 171, 64);
  --palette-orange-a400: rgb(255, 145, 0);
  --palette-orange-a700: rgb(255, 109, 0);
  --palette-deep-orange-50: rgb(251, 233, 231);
  --palette-deep-orange-100: rgb(255, 204, 188);
  --palette-deep-orange-200: rgb(255, 171, 145);
  --palette-deep-orange-300: rgb(255, 138, 101);
  --palette-deep-orange-400: rgb(255, 112, 67);
  --palette-deep-orange-500: rgb(255, 87, 34);
  --palette-deep-orange-600: rgb(244, 81, 30);
  --palette-deep-orange-700: rgb(230, 74, 25);
  --palette-deep-orange-800: rgb(216, 67, 21);
  --palette-deep-orange-900: rgb(191, 54, 12);
  --palette-deep-orange-a100: rgb(255, 158, 128);
  --palette-deep-orange-a200: rgb(255, 110, 64);
  --palette-deep-orange-a400: rgb(255, 61, 0);
  --palette-deep-orange-a700: rgb(221, 44, 0);
  --palette-brown-50: rgb(239, 235, 233);
  --palette-brown-100: rgb(215, 204, 200);
  --palette-brown-200: rgb(188, 170, 164);
  --palette-brown-300: rgb(161, 136, 127);
  --palette-brown-400: rgb(141, 110, 99);
  --palette-brown-500: rgb(121, 85, 72);
  --palette-brown-600: rgb(109, 76, 65);
  --palette-brown-700: rgb(93, 64, 55);
  --palette-brown-800: rgb(78, 52, 46);
  --palette-brown-900: rgb(62, 39, 35);
  --palette-grey-50: rgb(250, 250, 250);
  --palette-grey-100: rgb(245, 245, 245);
  --palette-grey-200: rgb(238, 238, 238);
  --palette-grey-300: rgb(224, 224, 224);
  --palette-grey-400: rgb(189, 189, 189);
  --palette-grey-500: rgb(158, 158, 158);
  --palette-grey-600: rgb(117, 117, 117);
  --palette-grey-700: rgb(97, 97, 97);
  --palette-grey-800: rgb(66, 66, 66);
  --palette-grey-900: rgb(33, 33, 33);
  --palette-blue-grey-50: rgb(236, 239, 241);
  --palette-blue-grey-100: rgb(207, 216, 220);
  --palette-blue-grey-200: rgb(176, 190, 197);
  --palette-blue-grey-300: rgb(144, 164, 174);
  --palette-blue-grey-400: rgb(120, 144, 156);
  --palette-blue-grey-500: rgb(96, 125, 139);
  --palette-blue-grey-600: rgb(84, 110, 122);
  --palette-blue-grey-700: rgb(69, 90, 100);
  --palette-blue-grey-800: rgb(55, 71, 79);
  --palette-blue-grey-900: rgb(38, 50, 56);
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);
  --color-dark-contrast: var(--color-white);
  --color-light-contrast: var(--color-black);
}
:root {
  --color-divider: var(--palette-grey-200);
  --color-background: var(--color-white);
  --color-text: var(--palette-grey-900);
  --color-text-secondary: var(--palette-grey-600);
  --color-primary: var(--palette-indigo-500);
  --color-primary-dark: var(--palette-indigo-700);
  --color-accent: var(--palette-pink-a200);
  --color-accent-dark: var(--palette-pink-700);
  --color-primary-contrast: var(--color-dark-contrast);
  --color-accent-contrast: var(--color-dark-contrast);
  --unit: 10px;
  --preferred-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 700;
  --shadow-2p:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --shadow-3p:
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --shadow-4p:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-6p:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --shadow-8p:
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --shadow-16p:
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --shadow-key-umbra-opacity: 0.2;
  --shadow-key-penumbra-opacity: 0.14;
  --shadow-ambient-shadow-opacity: 0.12;
  --zdepth-shadow-1: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  --zdepth-shadow-2: 0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  --zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);
  --z-index-highest: 300;
  --z-index-higher: 200;
  --z-index-high: 100;
  --z-index-normal: 1;
  --z-index-low: -100;
  --z-index-lower: -200
}
:root {
  --tooltip-background: color(rgb(97, 97, 97) a(90%));
  --tooltip-margin: calc(0.5 * var(--unit));
  --tooltip-border-radius: calc(0.2 * var(--unit));
  --tooltip-color: var(--color-white);
  --tooltip-font-size: var(--unit);
  --tooltip-max-width: calc(17 * var(--unit));
  --tooltip-padding: calc(0.8 * var(--unit));
  --tooltip-animation-duration: 200ms;
}
.tooltip {
  display: block;
  font-family: var(--preferred-font);
  font-size: var(--tooltip-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-size-small);
  max-width: var(--tooltip-max-width);
  padding: var(--tooltip-margin);
  pointer-events: none;
  position: absolute;
  text-align: center;
  text-transform: none;
  transform: scale(0) translateX(-50%);
  transform-origin: top left;
  transition: var(--animation-curve-default) var(--tooltip-animation-duration) transform;
  z-index: var(--z-index-higher);
  box-sizing: border-box;
  font-family: var(--preferred-font);
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%
}
.tooltip *,
    .tooltip *::after,
    .tooltip *::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  -webkit-touch-callout: none;
}
.tooltip.tooltipActive {
  transform: scale(1) translateX(-50%);
}
.tooltip.tooltipTop {
  transform: scale(0) translateX(-50%) translateY(-100%);
}
.tooltip.tooltipTop.tooltipActive {
  transform: scale(1) translateX(-50%) translateY(-100%);
}
.tooltip.tooltipLeft {
  transform: scale(0) translateX(-100%) translateY(-50%);
}
.tooltip.tooltipLeft.tooltipActive {
  transform: scale(1) translateX(-100%) translateY(-50%);
}
.tooltip.tooltipRight {
  transform: scale(0) translateX(0) translateY(-50%);
}
.tooltip.tooltipRight.tooltipActive {
  transform: scale(1) translateX(0) translateY(-50%);
}
.tooltipInner {
  background: var(--tooltip-background);
  border-radius: var(--tooltip-border-radius);
  color: var(--tooltip-color);
  display: block;
  padding: var(--tooltip-padding);
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

